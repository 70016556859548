import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, TextField, Typography, Box, Stack, List, ListItem, Modal } from "@mui/material";
import TabStore from "../../store/TabStore";
import LayoutList from "../../components/LayoutList";
import AuthApiService from "../../service/AuthApiService";
import Tags from "../../components/Tags";
import Swal from 'sweetalert2';
import { message } from '../../message/Message';
import { useCallback } from "react";

const mediaFrame = {
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  maxHeight:"80vh",
  maxWidth: "800px",
  backgroundColor: "#000"
};
const ModalImage = styled.img`
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;
const ModalVideo = styled.video`
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;
const ImgFrame = styled.div`
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  overflow: hidden;
  background-color: #000;
`;
const Img = styled.img`
  display: block;
  width: 100%;
`;
const Video = styled.video`
  display: block;
  width: 100%;
`;

export default function QuestionDetail() {
  const { setTabValue } = TabStore(); // 상단 탭

  const [ answer, setAnswer ] = useState(""); // 훈련 조언 (input: textarea)
  const [ buttonText, setButtonText ] = useState("답변하기"); // 전송 버튼 텍스트 (button) (답변하기 || 답변완료)

  const [ modalOpen, setModalOpen ] = useState(false); // 모달 열기, 닫기 컨트롤
  const [ modalSrc, setModalSrc ] = useState(""); // 모달 내부 미디어 src
  const [ modalMediaType, setModalMediaType ] = useState(""); // 모달 내부 미디어 타입 (image || video)

  const location = useLocation(); // 전달해준 페이지
  const detailData = location.state.questionData[ 0 ]; // 페이지 그릴 데이터
  const navigate = useNavigate();

  // when component did monted
  useEffect(() => {
    setTabValue("question-list");
    const reply = detailData.reply;
    // 답변 완료일 때 입력 비활성화
    if (detailData.reply !== null) {
      setAnswer(reply);
      setButtonText("답변완료");
    }
  }, []);

  // 답변제출 요청
  const registSuccess = () => {
    AuthApiService.registQuestionAnswer({
      questionId: detailData.id,
      reply: answer,
    })
      .then((response) => {
        console.log(response);
        Swal.fire({
          padding: '12px 16px 20px',
          text: message.regist.success,
          confirmButtonText: '확인',
          confirmButtonColor: '#575DFB'
        }).then(() => {
          navigate(-1);
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          padding: '12px 16px 20px',
          text: message.regist.error.fail,
          confirmButtonText: '확인',
          confirmButtonColor: '#575DFB'
        }).then(() => {
          navigate(-1);
        });
      });
  };


  // 이미지 리스트 컴포넌트
  const ImageList = useCallback(() => {
    const listedImage = detailData && detailData.resourceContainer.items.map((item) => {
      // 비디오 확장자면
      if (returnFileType(item.originalFileName) === "video") {
        return (
          <ImgFrame
            onClick={ () => {
              setModalOpen(true);
              setModalSrc(item.uri);
              setModalMediaType("video");
            } }
            key={ item.id }
          >
            <Video src={ item.uri } />
          </ImgFrame>
        );
      } else {
        return (
          <ImgFrame
            onClick={ () => {
              setModalOpen(true);
              setModalSrc(item.uri);
              setModalMediaType("image");
            } }
            key={ item.id }
          >
            <Img src={ item.uri } alt={ item.originalFileName } />
          </ImgFrame>
        );
      }
    });
    return listedImage;
  }, [ modalSrc ]);

  // 미디어 file name에서 확장자명 추출
  const returnFileType = (filename) => {
    const extension = filename.split(".").pop();
    if ([ "mp4", "mov" ].includes(extension)) {
      return "video";
    } else {
      return "image";
    }
  };

  // 미디어 미리보기용 모달 컴포넌트
  function PreviewModal() {
    return (
      <Modal
        open={ modalOpen }
        onClose={ () => setModalOpen(false) }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ mediaFrame }>
          { modalMediaType === "image" ? (
            <ModalImage src={ modalSrc } alt="uploaded-img" />
          ) : modalMediaType === "video" ? (
            <ModalVideo controls src={ modalSrc } />
          ) : null }
        </Box>
      </Modal>
    );
  }

  return (
    <>
      <LayoutList>
        <Box sx={ { mb: 6, backgroundColor: "#fff" } }>
          {/* 교육 항목 */ }
          <List>
            <ListItem sx={ { borderBottom: "1px solid #ddd" } } disablePadding>
              <Box sx={ { py: 2, width: "100%" } }>
                <Box
                  sx={ {
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    gap: "32px",
                    fontSize: 14,
                  } }
                >
                  <Box
                    sx={ {
                      width: "100%",
                      maxWidth: "fit-content",
                      minWidth: "fit-content",
                      mr: 6,
                    } }
                  >
                    {/* 강아지이름 */ }
                    { detailData.subjectTake.pet.name }
                  </Box>
                  <Box sx={ { wordBreak: "keep-all" } }>
                    <Tags color="primary">{ detailData.subjectStep.step }단계</Tags>
                  </Box>
                  <Box
                    sx={ {
                      width: "100%",
                      display: "flex",
                    } }
                  >
                    <div style={ { display: "flex", width: "440px" } }>
                      <div
                        style={ {
                          width: "fit-content",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        } }
                      >
                        {/* 훈련이름 */ }
                        { detailData.subjectTake.subjectTitle }
                      </div>
                    </div>
                  </Box>
                  <Box
                    sx={ {
                      width: "100%",
                      maxWidth: "fit-content",
                      minWidth: "fit-content",
                    } }
                  >
                    {/* 요청일 */ }
                    { detailData.createdAt }
                  </Box>
                </Box>
              </Box>
            </ListItem>
          </List>
          <Box sx={ { p: 2 } }>
            {/* 내용 */ }
            <Box sx={ { mb: 1, fontWeight: 700 } }>
              {/* 제목 */ }
              { detailData ? detailData.title : null }
            </Box>
            <Box sx={ { mb: 4 } }>
              {/* 작성내용 */ }
              { detailData ? detailData.description : null }
            </Box>
            <Stack direction="row" spacing={ 2 }>
              <ImageList />
            </Stack>
            {/* 미디어 보기 모달 */ }
            <PreviewModal />
          </Box>
          <Box sx={ { p: 2 } }>
            <Typography sx={ { fontWeight: 800, mb: 1 } }>
              질문 답변하기
            </Typography>
            {/* 훈련 조언 */ }
            <TextField
              multiline
              rows={ 4 }
              value={ answer }
              onChange={ (e) => {
                setAnswer(e.target.value);
              } }
              fullWidth
              placeholder="답변을 작성해주세요."
              disabled={ detailData.reply ? true : false }
            />
          </Box>
        </Box>
        <Box sx={ { display: "flex", justifyContent: "center" } }>
          <Button
            variant="contained"
            size="large"
            sx={ { display: "block", mb: 2, width: 460 } }
            disabled={ detailData.reply ? true : false }
            onClick={ registSuccess }
          >
            { buttonText }
          </Button>
        </Box>
      </LayoutList>
    </>
  );
}


export const message = {
        regist: {
            success: "등록이 완료되었습니다.",
            error: {
                fail: "등록에 실패했습니다. 관리자에게 문의해주세요.",
            }
        },
        file: {
            error: {
                exist: "파일을 한개 이상 선택해주세요.",
                size: "전체 파일 용량은 50MB를 넘길 수 없습니다.",
                length: "파일의 최대 개수는 5개 입니다.",
                valid: "유효하지 않은 파일 형식 입니다."
            }
        },
        input: {
            error : {
                exist: "내용을 모두 입력 해주세요.",
                valid : "유효하지 않은 값 입니다."
            }
        }
}
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LayoutList from "../../components/LayoutList";
import TabStore from "../../store/TabStore";
import Tags from "../../components/Tags";
import AuthApiService from "../../service/AuthApiService";
import { Stack, Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import {DataGrid,gridPageCountSelector,gridPageSelector,useGridApiContext,useGridSelector} from "@mui/x-data-grid";
import AuthStore from "../../store/AuthStore";
import axios from "axios";
import axiosInterceptor from '../../util/TokenUtil';


const Icon = styled.img`
  display: block;
  width: 12px;
  height: auto;
  margin-left: 4px;
`;

export default function CertificationList() {
  const { setTabValue } = TabStore();
  const [ listData, setListData ] = useState([]); // 리스트 그릴 데이터
  const { getAccessToken, accessToken, getRefreshToken, setAccessToken, setRefreshToken } = AuthStore();


  const fetchCertificationList = () => {
      axios.defaults.headers.common[ 'Authorization' ] = `Bearer ${getAccessToken()}`;

      AuthApiService.requestCertificationList()
      .then((response) => {
        console.log("response", response);
        setListData(response.data.result);
      }).catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    axiosInterceptor(
      getAccessToken,
      getRefreshToken,
      setAccessToken,
      setRefreshToken
    );
    // 새로고침 시 상단 탭 [certification-list]로 유지
    setTabValue("certification-list");
    // 리스트 불러오기
    fetchCertificationList();



  }, []);

  // 리스트 상태 태그 컬러
  const sortColor = (status) => {
    switch (status) {
      case "답변필요":
        return "secondary";
      case "교육완료":
        return "primary";
      case "다시공부":
        return "pale";
      default:
        break;
    }
  };

  const columns = [
    {
      field: "col1",
      headerName: "강아지 이름",
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "col2",
      headerName: "상태",
      flex: 1,
      maxWidth: 100,
      renderCell: (params) => (
        <Tags color={sortColor(params.value)}>{params.value}</Tags>
      ),
    },
    {
      field: "col3",
      headerName: "훈련 이름",
      flex: 1,
      renderCell: (params) => (
        <Box
          component={Link}
          to={"/certification/detail"}
          state={{
            certificationData: listData.filter((item) => {
              return item.id === params.id;
            }),
          }}
          sx={{display:"flex",width:"100%",height:"100%", alignItems:"center"}}
        >
          {params.value}
          <Icon />
        </Box>
      ),
    },
    {
      field: "col4",
      headerName: "요청일",
      flex: 1,
      maxWidth: 180,
    },
  ];

  // 상태 한글 변환
  const status = (originalStatus) => {
    if (originalStatus === "REQUEST") {
      return "답변필요";
    } else if (originalStatus === "SUCCEED") {
      return "답변완료"
    } else if (originalStatus === "AGAIN") {
      return "다시하기";
    }
  }

  const rows = listData.map((item, index) => {
    // UTC to Local date
    let dateData = item.createdAt + "Z";
    dateData = dateData.replace(/\s/g, "T");
    const date = new Date(dateData);

    return {
      id: item.id,
      col1: item.pet.name,
      col2: status(item.succeed),
      col3: item.subjectTitle,
      col4: date.toLocaleString(),
    };
  });

  // pagination
  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }
  


  return (
    <>
      <LayoutList>
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: () => "",
              ErrorOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  데이터를 불러오는데 실패했습니다. 관리자에게 문의해주세요.
                </Stack>
              ),
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "col4", sort: "desc" }],
              },
            }}
          />
        </div>
      </LayoutList>
    </>
  );
}

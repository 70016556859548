import React, { useState, useEffect } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import AuthStore from "../../store/AuthStore";
import LoginStore from "../../store/LoginStore";
import ApiService from "../../service/ApiService";
import { useNavigate } from "react-router-dom";
import OnLoginSuccess from "./OnLoginSuccess";
import axios from "axios";



export default function Login() {
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(false); // 전체 input value 검사
  const [isLoginError, setLoginError] = useState(false); // 로그인 에러인지
  // 로그인 기능 관련 state
  const { setAccessToken, setRefreshToken, setUserId, setLogin } = AuthStore();
  const { username, setUserName, password, setPassword, getLoginData } = LoginStore();







  // 폼 전체 입력 확인 -> 활성화
  useEffect(() => {
    if (username && password) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [username, password]);

  // 로그인 양식 제출
  const handleSubmit = () => {

    ApiService.requestLogin(getLoginData())
      .then((response) => {
        console.log(response);
        setUserId(response.data.result.username); // 훈련사 아이디 저장 (storage)

        OnLoginSuccess(response, setRefreshToken, setAccessToken, setLogin);
        const accessToken = response.data.result.accessToken;
        axios.defaults.headers.common[ 'Authorization' ] = `Bearer ${accessToken}`;
        
        navigate("/certification/list"); // 로그인 후 질문함으로 이동
      })
      .catch((error) => {

        console.log(error);
        setLoginError(true);

      });
  };

  const handleOnKeyPress = e => {
  if (e.key === 'Enter') {
    handleSubmit(); // Enter 입력이 되면 클릭 이벤트 실행
  }
};
  return (
    <form onKeyDown={handleOnKeyPress}>
      <Box
        sx={{
          width: 700,
          margin: "64px auto",
          padding: "120px",
          backgroundColor: "#EFEFF8",
          textAlign: "center",
        }}
      >
        <Box sx={{ fontWeight: 700, fontSize: 18, color: "#575dfb", mb: 2 }}>
          <Link to="/">PETMOA for TRAINER</Link>
        </Box>
        <h2 style={{ marginBottom: 80 }}>훈련관리 페이지</h2>
        <TextField
          sx={{ mb: 2, backgroundColor: "#fff" }}
          fullWidth
          placeholder="아이디"
          size="small"
          onChange={(event) => {
            setUserName(event.target.value);
          }}
          autoComplete="on"
        />
        <TextField
          sx={{ mb: 2, backgroundColor: "#fff" }}
          fullWidth
          type="password"
          placeholder="비밀번호"
          size="small"
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          autoComplete="on"
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Link
            to="/find-id"
            style={{ display: "inline-block", marginBottom: 16 }}
          >
            아이디・비밀번호 찾기
          </Link>
        </Box>

        <Box style={{ display: isLoginError ? "block" : "none" }}>
          <Typography sx={{ mb: 2, color: "tomato", fontSize: 14 }}>
            아이디 또는 비밀번호를 잘못 입력했습니다.
            <br />
            입력하신 내용을 다시 확인해주세요.
          </Typography>
        </Box>
        <Button
          variant="contained"
          size="large"
          sx={{ display: "block", mb: 2 }}
          fullWidth
          onClick={handleSubmit}
          disabled={!isValid}
        >
          로그인 하기
        </Button>
        <Link to="/join">
          <Button
            variant="outlined"
            size="large"
            sx={{ display: "block", mb: 2 }}
            fullWidth
          >
            훈련사 등록하기
          </Button>
        </Link>
      </Box>
    </form>
  );
}

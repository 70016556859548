import React from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import { Link, useNavigate } from "react-router-dom";
import TabStore from "../store/TabStore";
import { Button } from "@mui/material";
import AuthStore from "../store/AuthStore";
import LoginStore from "../store/LoginStore";



export default function Layout(props) {
  const {tabValue, setTabValue} = TabStore();
  const { setUserId, setLogin, setAccessToken, setRefreshToken } = AuthStore();
  const { setUserName, setPassword } = LoginStore();
  const {userId} = JSON.parse(localStorage.getItem("authInfo")).state;
  const navigate = useNavigate();

  // 로그아웃
  const logout = () => {
    setAccessToken(null);
    setRefreshToken(null);
    setUserName('');
    setPassword('');
    setUserId(null);
    setLogin(false);
    navigate('/');
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <main className="header-list">
        <section className="header-list">
          <div>
            <Box
              sx={{ borderBottom: 1, borderColor: "transparent", mb: "-14px" }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="primary tabs example"
              >
                <Tab
                  value="question-list"
                  label="받은 질문"
                  component={Link}
                  to={"/question/list"}
                />
                <Tab
                  value="certification-list"
                  label="훈련완료 인증"
                  component={Link}
                  to={"/certification/list"}
                />
              </Tabs>
            </Box>
          </div>
          <div>
            <span style={{ fontSize: 14 }}>{userId} 훈련사님</span>
            <Button sx={{ ml: 1 }} onClick={logout}>
              로그아웃
            </Button>
          </div>
        </section>
      </main>
      <main className="main-list">
        <section>{props.children}</section>
      </main>
    </>
  );
}

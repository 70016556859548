import React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Link } from "react-router-dom";
import TabStore from "../store/TabStore";
import { Box } from "@mui/material";

function FindTabs() {
  const { findTabValue, setFindTabValue } = TabStore();
  const handleChange = (event, newValue) => {
    setFindTabValue(newValue);
  };
  return (
    <Box sx={{ borderBottom: 1, borderColor: "transparent", mb: "-26px", mt:2 }}>
      <Tabs
        value={findTabValue}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
      >
        <Tab
          value="find-id"
          label="아이디 찾기"
          component={Link}
          to={"/find-id"}
        />
        <Tab
          value="find-password"
          label="비밀번호 재설정"
          component={Link}
          to={"/find-password"}
        />
      </Tabs>
    </Box>
  );
}
export default function Layout({title, tabs, children}) {
  return (
    <>
      <main className="header-join">
        <section className="header-join">
          <Box sx={{fontWeight:700, fontSize:18, color:"#575dfb", mb:2}}>
            <Link to="/">PETMOA for TRAINER</Link>
          </Box>
          <h1 style={{fontSize:34}}>{title}</h1>
          {/* {
            !tabs ? null : <FindTabs />
          } */}
        </section>
      </main>
      <main>
        <section>{children}</section>
      </main>
    </>
  );
}

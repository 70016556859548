import create from "zustand";

const TabStore = create((set) => ({
  // 질문함, 훈련함 tab 스타일링을 위한 state
  tabValue: "question-list",
  setTabValue: (tabValue) => set({ tabValue: tabValue }),


  // 아이디, 비밀번호찾기 tab 스타일링을 위한 state
  findTabValue: "find-id",
  setFindTabValue: (findTabValue) => set({ findTabValue: findTabValue }),
}));

export default TabStore;

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LayoutJoin from "../../components/LayoutJoin";
import {
  Button,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TabStore from "../../store/TabStore";



export default function FindPassword() {
  const [setTrainerId] = useState(""); // 아이디
  const [setTrainerName] = useState(""); // 이름
  const [gender, setGender] = useState(""); // 성별
  const [trainerBirth, setTrainerBirth] = useState(); // 생년월일

  // 새로고침 시 상단 탭 find-password로 유지
  const { setFindTabValue } = TabStore();
  useEffect(() => {
    setFindTabValue("find-password");
  }, []);

  return (
    <>
      <LayoutJoin title="아이디 비밀번호 찾기" tabs>
        <h2>비밀번호 재설정</h2>
        <Box sx={{ p: 6, mb: 6, backgroundColor: "#fff" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box>
                  <label htmlFor="trainerId">
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      아이디
                    </Typography>
                  </label>
                  <TextField
                    id="trainerId"
                    type="search"
                    variant="outlined"
                    placeholder="아이디 입력"
                    fullWidth
                    className="input-padding-sm"
                    onChange={(event) => {
                      setTrainerId(event.target.value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <label htmlFor="trainerName">
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      성명
                    </Typography>
                  </label>
                  <TextField
                    id="trainerName"
                    type="search"
                    variant="outlined"
                    placeholder="이름"
                    fullWidth
                    className="input-padding-sm"
                    onChange={(event) => {
                      setTrainerName(event.target.value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <label htmlFor="trainerName">
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      성별
                    </Typography>
                  </label>
                  <ToggleButtonGroup
                    fullWidth
                    sx={{ height: "54px" }}
                    color="primary"
                    value={gender}
                    exclusive
                    onChange={(event, newData) => {
                      if (newData !== null) {
                        setGender(newData);
                      }
                    }}
                  >
                    <ToggleButton value="male">남자</ToggleButton>
                    <ToggleButton value="female">여자</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ mb: 2 }}>
                  <label htmlFor="trainerBirth">
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      생년월일
                    </Typography>
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      openTo="year"
                      views={["year", "month", "day"]}
                      inputFormat={"yyyy-MM-dd"}
                      mask={"____-__-__"}
                      value={trainerBirth}
                      onChange={(dateValue) => {
                        // dateValue.toLocaleDateString ex> 2018. 5. 9.
                        setTrainerBirth(dateValue.toLocaleDateString());
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={null}
                          fullWidth
                          variant="outlined"
                          placeholder="강아지는 언제 태어났나요?"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </LayoutJoin>
      <Button
        variant="contained"
        size="large"
        sx={{ display: "block", width: 460, margin: "80px auto" }}
        // disabled={isValid}
        // onClick={startBtnHandler}
      >
        다음
      </Button>
    </>
  );
}

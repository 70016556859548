import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LayoutJoin from "../../components/LayoutJoin";
import {Button,TextField,ToggleButtonGroup,ToggleButton,Typography} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ApiService from "../../service/ApiService";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { message } from '../../message/Message';
import ruLocale from 'date-fns/locale/ru';
import koLocale from 'date-fns/locale/ko';

const localeMap = {
  ru: ruLocale,
  ko: koLocale,
};

export default function FindId() {
  const [trainerName, setTrainerName] = useState(""); // 이름
  const [gender, setGender] = useState(""); // 성별
  const [trainerBirth, setTrainerBirth] = useState(null); // 생년월일
  const [isValid, setIsValid] = useState(false); // 전체 input value 검사
  const [responseData, setResponseDate] = useState();
  // 폼 전체 입력 확인 -> 활성화
  useEffect(() => {
    if (trainerName && gender && trainerBirth) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [trainerName, gender, trainerBirth]);

  const request = () => {
    ApiService.requestFindAccount({
      username: trainerName,
      gender: gender,
      birth: trainerBirth,
    })
      .then((response) => {
        console.log(response);
        setResponseDate(response.data.result);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          padding: '12px 16px 20px',
          text: message.regist.error.fail,
          confirmButtonText: '확인',
          confirmButtonColor: '#575DFB'
        });
      });
  };

  return (
    <>
      <LayoutJoin title="아이디 찾기" tabs>
        <h2>이름 / 생년월일 / 성별로 찾기</h2>
        <Box sx={{ p: 6, mb: 6, backgroundColor: "#fff" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box>
                  <label htmlFor="trainerName">
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      성명
                    </Typography>
                  </label>
                  <TextField
                    id="trainerName"
                    type="search"
                    variant="outlined"
                    placeholder="이름"
                    fullWidth
                    className="input-padding-sm"
                    onChange={(event) => {
                      setTrainerName(event.target.value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <label htmlFor="trainerName">
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      성별
                    </Typography>
                  </label>
                  <ToggleButtonGroup
                    fullWidth
                    sx={{ height: "54px" }}
                    color="primary"
                    value={gender}
                    exclusive
                    onChange={(event, newData) => {
                      if (newData !== null) {
                        setGender(newData);
                      }
                    }}
                  >
                    <ToggleButton value="MALE">남자</ToggleButton>
                    <ToggleButton value="FEMALE">여자</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ mb: 2 }}>
                  <label htmlFor="trainerBirth">
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      생년월일
                    </Typography>
                  </label>
                  <LocalizationProvider dateAdapter={ AdapterDateFns } adapterLocale={ localeMap.ko }>
                    <DatePicker
                      openTo="year"
                      views={["year", "month", "day"]}
                      inputFormat={"yyyy-MM-dd"}
                      value={trainerBirth}
                      onChange={(dateValue) => {
                        setTrainerBirth(
                          dateValue.toISOString().substring(0, 10)
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          readOnly
                        />
                      )}
                      disableFuture={ true }
                      minDate={ new Date("1950-01-01") }
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {responseData ? (
          <Box
            sx={{
              textAlign: "center",
              fontSize: 18,
            }}
          >
            훈련사님의 아이디는&nbsp;
            <span style={{ color: "#575dfb", fontWeight: 800 }}>
              {responseData.username}
            </span>
            &nbsp; 입니다.
          </Box>
        ) : null}
      </LayoutJoin>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          mt: 8,
        }}
      >
        <Button
          variant="contained"
          size="large"
          sx={{ display: "block", width: 460, mb: 1 }}
          disabled={!isValid}
          onClick={request}
        >
          아이디 찾기
        </Button>
        {responseData ? (
          <>
            <Link
              to="/set-password"
              state={{
                foundData: responseData,
                inputData: {
                  username: trainerName,
                  gender: gender,
                  birth: trainerBirth,
                },
              }}
            >
              <Button
                variant="outlined"
                size="large"
                sx={{ display: "block", width: 460, mb: 1 }}
                disabled={!isValid}
                onClick={request}
              >
                비밀번호 변경하기
              </Button>
            </Link>
            <Link to="/">
              <Button
                variant="outlined"
                size="large"
                sx={{ display: "block", width: 460, mb: 1 }}
                disabled={!isValid}
                onClick={request}
              >
                로그인
              </Button>
            </Link>
          </>
        ) : null}
      </Box>
    </>
  );
}

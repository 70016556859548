import axios from "axios";
import AuthApiService from "../service/AuthApiService";

export default function axiosInterceptor(
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken
) {

  const getAccesstokenWithRefreshtoken = async() => {
    const data = {refresh : getRefreshToken()}
    
    await AuthApiService.reqAccessToken(data)
    .then(async response => {
      const {accessToken, refreshToken} = response.data.result;

      // response가 정상이라면 setToken
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);

      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      return await accessToken;
    }).catch(error => {
      console.log('reqAccessToken.error', error);
    })
  }

  let isTokenRefreshing = false;
  let refreshSubscribers = [];

  const onTokenRefreshed = (originalRequest) => {
    originalRequest.headers.Authorization = "Bearer " + getAccessToken();
    refreshSubscribers.map((callback) => callback(getAccessToken()));
    refreshSubscribers = [];
  };
  
  const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
  };

  axios.interceptors.response.use(
    //정상 응답 처리
    (response) => {
      return response;
    },
    //오류 발생 시
    async (error) => {
      const {
        config,
        response: { status },
      } = error;

      // config.headers.Authorization 없을 때
      // axios.defaults.headers.common[ 'Authorization' ] = `Bearer ${getAccessToken()}`;
      
      
      const originalRequest = config;
      
      if (status === 401) {
        if (!isTokenRefreshing) {
          // isTokenRefreshing이 false인 경우에만 token refresh 요청
          isTokenRefreshing = true;
          
          //엑세스 토큰 요청 및 
          await getAccesstokenWithRefreshtoken();

          isTokenRefreshing = false;
        }
        // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
        const retryOriginalRequest = new Promise((resolve) => {
          addRefreshSubscriber(() => {
            resolve(axios(originalRequest));
          });
        });
        
        // 새로운 토큰으로 지연되었던 요청 진행
        onTokenRefreshed(originalRequest);

        return retryOriginalRequest;
      }

      return Promise.reject(error);
    }
  );
}


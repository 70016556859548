const OnLoginSuccess = (
  response, 
  setRefreshToken, 
  setAccessToken, 
  setLogin,
) => {
  console.log(response);

  const {result} = response.data;
  // set AuthStore
  setRefreshToken(result.refreshToken);
  setAccessToken(result.accessToken);
  
  //로그인 State true
  setLogin(true);


  return '로그인 성공';
}

export default OnLoginSuccess;

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LayoutJoin from "../../components/LayoutJoin";
import { Button, TextField, Typography } from "@mui/material";
import ApiService from "../../service/ApiService";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { message } from '../../message/Message';

export default function RegistAccount() {
  const [trainerId, setTrainerId] = useState(""); // 아이디
  const [password, setPassword] = useState(); // 새 비밀번호
  const [verifyPassword, setVerifyPassword] = useState(); // 새 비밀번호 확인
  const [isValidPassword, setIsvalidPassword] = useState(); // 비밀번호 유효한지
  const [isValidId, setIsvalidId] = useState(); // 아이디 유효한지
  const [isValid, setIsValid] = useState(false); // 전체 유효 검사

  // 폼 전체 입력 확인 -> 활성화
  useEffect(() => {
    if (trainerId && password && verifyPassword) {
      password === verifyPassword ? setIsValid(true) : setIsValid(false);
    } else {
      setIsValid(false);
    }
  }, [trainerId, password, verifyPassword]);

  const navigate = useNavigate();

  // 아이디 정규식 for validation check -> ~30자리, 영문 + 숫자
  const idRegex = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{4,30}$/g;
  // 비밀번호 정규식 for validation check -> 8~16자리, 영문 + 숫자
  const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{8,16}$/g;

  // 아이디 유효성 검사 함수 (onChange)
  const idValidator = (event) => {
    setTrainerId(event.target.value);
    if (!event.target.value.match(idRegex)) {
      setIsvalidId(false);
    } else {
      setIsvalidId(true);
    }
  };

  // 비밀번호 유효성 검사 함수 (onChange)
  const passwordVaildator = (event) => {
    setPassword(event.target.value);
    if (!event.target.value.match(passwordRegex)) {
      setIsvalidPassword(false);
    } else {
      setIsvalidPassword(true);
    }
  };


  const register = () => {
    // TODO : UUID 전달 방식 어떻게 처리할 지
    const uuid = `c4796668-cf9a-43fa-8d2f-d646327c8a8a`;
    
    ApiService.requestRegistAccount({
      username: trainerId,
      password: password,
      uuid: uuid
    })
      .then((response) => {
        console.log(response);
        Swal.fire({
          padding: '12px 16px 20px',
          text: message.regist.success,
          confirmButtonText: '확인',
          confirmButtonColor: '#575DFB'
        }).then(() => {
          navigate("/login");
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          padding: '12px 16px 20px',
          text: message.regist.error.fail,
          confirmButtonText: '확인',
          confirmButtonColor: '#575DFB'
        });
      });
  };

  return (
    <>
      <LayoutJoin title="계정등록">
        <Box sx={{ p: 6, mb: 6, backgroundColor: "#fff" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box>
                  <label htmlFor="trainerId">
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      아이디
                    </Typography>
                  </label>
                  <TextField
                    id="trainerId"
                    type="search"
                    variant="outlined"
                    placeholder="아이디 입력"
                    fullWidth
                    className="input-padding-sm"
                    onChange={idValidator}
                    helperText={
                      isValidId
                        ? ""
                        : "영문, 숫자로 ~30 자리로 입력해주세요."
                    }
                    error={!isValidId}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <label htmlFor="Password">
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      새 비밀번호 입력
                    </Typography>
                  </label>
                  <TextField
                    id="Password"
                    type="password"
                    variant="outlined"
                    placeholder="영문, 숫자로 8~15 자리로 입력해주세요."
                    fullWidth
                    className="input-padding-sm"
                    onChange={passwordVaildator}
                    helperText={
                      isValidPassword
                        ? ""
                        : "영문, 숫자로 8~15 자리로 입력해주세요."
                    }
                    error={!isValidPassword}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <label htmlFor="verifyPassword">
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      새 비밀번호 확인
                    </Typography>
                  </label>
                  <TextField
                    id="verifyPassword"
                    error={verifyPassword !== password ? true : false}
                    type="password"
                    variant="outlined"
                    placeholder="동일한 비밀번호를 입력해주세요."
                    fullWidth
                    className="input-padding-sm"
                    helperText={
                      verifyPassword !== password
                        ? "동일한 비밀번호를 입력해주세요."
                        : null
                    }
                    onChange={(event) => {
                      setVerifyPassword(event.target.value);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </LayoutJoin>
      <Button
        variant="contained"
        size="large"
        sx={{ display: "block", width: 460, margin: "80px auto" }}
        disabled={!isValid}
        onClick={register}
      >
        다음
      </Button>
    </>
  );
}

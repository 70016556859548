import axios from "axios";
import { PET_API_URI, AUTH_URI } from "./Host";


class AuthApiService {
 
  //훈련인증 리스트 요청
  requestCertificationList = async () => {
    return await axios.get(PET_API_URI + `/request`);
  };

  //교육(훈련)완료 승인
  requestCertificationSuccess = async ({ reply, again, subjectTakeId }) => {
    return await axios.post(PET_API_URI + `/success/${subjectTakeId}`,{ reply, again });
  };

  //문의 리스트 요청
  requestQuestionList = async () => {
    return await axios.get(PET_API_URI + `/question/trainer`);
  };
  //문의 답변
  registQuestionAnswer = async ({questionId, reply}) => {
    return await axios.post(
      PET_API_URI + `/question/${questionId}/reply`,{ reply });
  };

  //refreshToken 이용하여 accessToken 요청
  reqAccessToken = async (data) => {
    return await axios.post(AUTH_URI + '/refresh', data,
      { headers: { 'Content-Type': 'application/json' } }
    );
  }
}

export default new AuthApiService();




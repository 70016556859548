import create from 'zustand';
import { persist } from 'zustand/middleware'

const INIT_STR = '';

const AuthStore = create(
  persist(
    (set, get) => ({
      refreshToken: INIT_STR,
      accessToken: INIT_STR,
      getRefreshToken: () => get().refreshToken,
      getAccessToken: () => get().accessToken,

      setRefreshToken: (refToken) => {
        return set({ refreshToken: refToken })
      },
      setAccessToken: (accToken) => {
        return set({ accessToken: accToken })
      },

      userId: null,
      isLogin: false,

      setUserId: (userId) => set({ userId: userId }),
      setLogin: (isLogin) => set({ isLogin: isLogin }),
    }),
    {
      name: 'authInfo',
    }
  )
)

export default AuthStore;

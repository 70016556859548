import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LayoutJoin from "../../components/LayoutJoin";
import {Button,TextField,Typography} from "@mui/material";
import TabStore from "../../store/TabStore";
import { useLocation, useNavigate } from "react-router-dom";
import ApiService from "../../service/ApiService";
import Swal from 'sweetalert2';
import { message } from '../../message/Message';

export default function SetPassword() {
  const [newPassword, setNewPassword] = useState(); // 새 비밀번호
  const [verifyNewPassword, setVerifyNewPassword] = useState(); // 새 비밀번호 확인
  const [isValid, setIsValid] = useState(false);
  const [isValidPassword, setIsvalidPassword] = useState(); // 비밀번호 유효한지
  const location = useLocation();
  const foundData = location.state.foundData;
  const inputData = location.state.inputData;
  // 새로고침 시 상단 탭 find-password로 유지
  const { setFindTabValue } = TabStore();
  const navigate = useNavigate();
  
  useEffect(() => {
    setFindTabValue("find-password");
    verifyNewPassword === newPassword ? setIsValid(true) : setIsValid(false);
  }, [verifyNewPassword, newPassword, setFindTabValue, isValid]);


  const request = () => {
    ApiService.requestFindAccount({
      userId: foundData.username,
      username: inputData.username,
      gender: inputData.gender,
      birth: inputData.birth,
      uuid: foundData.uuid,
    })
      .then((response) => {
        console.log(response);
        Swal.fire({
          padding: '12px 16px 20px',
          text: '비밀번호가 변경되었습니다. 변경된 비밀번호로 로그인 해주세요.',
          confirmButtonText: '확인',
          confirmButtonColor: '#575DFB'
        }).then(() => {
          navigate("/");
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          padding: '12px 16px 20px',
          text: message.regist.error.fail,
          confirmButtonText: '확인',
          confirmButtonColor: '#575DFB'
        });
      });
  };

  // 비밀번호 정규식 for validation check -> 8~16자리, 영문 + 숫자
  const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{8,16}$/g;
  // 비밀번호 유효성 검사 함수 (onChange)
  const passwordVaildator = (event) => {
    setNewPassword(event.target.value);
    if (!event.target.value.match(passwordRegex)) {
      setIsvalidPassword(false);
    } else {
      setIsvalidPassword(true);
    }
  };
  return (
    <>
      <LayoutJoin title="아이디 비밀번호 찾기" tabs>
        <h2>비밀번호 재설정</h2>
        <Box sx={{ p: 6, mb: 6, backgroundColor: "#fff" }}>
          <Box sx={{ flexGrow: 1 }}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <label htmlFor="newPassword">
                      <Typography sx={{ mb: 1, fontWeight: 700 }}>
                        새 비밀번호 입력
                      </Typography>
                    </label>
                    <TextField
                      id="newPassword"
                      type="password"
                      variant="outlined"
                      placeholder="영문, 숫자로  8~15 자리로 입력해주세요."
                      fullWidth
                      className="input-padding-sm"
                      onChange={(event) => {
                        passwordVaildator(event);
                      }}
                      autoComplete="off"
                      error={isValidPassword ? false : true}
                      helperText={
                        isValidPassword
                          ? null
                          : "영문, 숫자로 8~15 자리로 입력해주세요."
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <label htmlFor="verifyNewPassword">
                      <Typography sx={{ mb: 1, fontWeight: 700 }}>
                        새 비밀번호 확인
                      </Typography>
                    </label>
                    <TextField
                      id="verifyNewPassword"
                      error={verifyNewPassword !== newPassword ? true : false}
                      type="password"
                      variant="outlined"
                      placeholder="동일한 비밀번호를 입력해주세요."
                      fullWidth
                      className="input-padding-sm"
                      autoComplete="off"
                      helperText={
                        verifyNewPassword !== newPassword
                          ? "동일한 비밀번호를 입력해주세요."
                          : null
                      }
                      onChange={(event) => {
                        setVerifyNewPassword(event.target.value);
                        if (verifyNewPassword !== newPassword) {
                          setIsValid(false);
                        }
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </LayoutJoin>
      <Button
        variant="contained"
        size="large"
        sx={{ display: "block", width: 460, margin: "80px auto" }}
        disabled={isValid ? false : true}
        onClick={request}
      >
        비밀번호 변경하기
      </Button>
    </>
  );
}

import React, {useEffect} from "react";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./styles/reset.css";
import "./styles/global.css";
import Join from "./pages/join/Join";
import RegistAccount from "./pages/join/RegistAccount";
import Login from "./pages/login/Login";
import FindId from "./pages/login/FindId";
import FindPassword from "./pages/login/FindPassword";
import SetPassword from "./pages/login/SetPassword";
import QuestionList from "./pages/question/List";
import QuestionDetail from "./pages/question/Detail";
import CertificationList from "./pages/certification/List";
import CertificationDetail from "./pages/certification/Detail";
import AuthRoute from "./components/route/AuthRoute";
import AuthStore from "./store/AuthStore";
import axiosInterceptor from './util/TokenUtil';
import axios from "axios";




const theme = createTheme({
  palette: {
    primary: {
      main: "#575DFB",
    },
    secondary: {
      main: "#F1F2FF",
    },
  },
  typography: {
    fontSize: 14,
  },
  ptSm: {
    paddingTop: "8px",
  },
  stepper: {
    iconColor: "green", // or logic to change color
  },
});

export default function App() {

  const {getAccessToken,getRefreshToken,setAccessToken,setRefreshToken} = AuthStore();

  useEffect(() => {
    axiosInterceptor(
      getAccessToken,
      getRefreshToken,
      setAccessToken,
      setRefreshToken
    );
  }, []);

  const { isLogin } = AuthStore();
  

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          {/* 회원가입 */}
          <Route path="/join" element={<Join />}></Route>
          <Route path="/regist-account" element={<RegistAccount />}></Route>

          {/* 로그인 */}
          <Route path="/login" element={<Login />}></Route>
          <Route path="/find-id" element={<FindId />}></Route>
          <Route path="/find-password" element={<FindPassword />}></Route>
          <Route path="/set-password" element={<SetPassword />}></Route>

          {/* 메인 */}
          <Route path="/main" element={<Login />}></Route>
          <Route path="/" element={<Login />}></Route>

          {/* 질문하기 */}
          <Route
            path="/question/list"
            element={isLogin ? <QuestionList /> : <Navigate to="/" replace />}
          ></Route>
          <Route
            path="/question/detail"
            element={isLogin ? <QuestionDetail /> : <Navigate to="/" replace />}
          ></Route>

          {/* 훈련인증 */}
          <Route
            path="/certification/list"
            element={isLogin ? <CertificationList /> : <Navigate to="/" replace />}
          ></Route>
          <Route
            path="/certification/detail"
            element={isLogin ? <CertificationDetail /> : <Navigate to="/" replace />}
          ></Route>

          {/* 일치하는 path가 없을 경우 로그인 페이지로 이동 */}
          <Route
            path="*"
            element={<AuthRoute isLogin={isLogin} component={Login} />}
          />
          
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}




import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthStore from "../../store/AuthStore";

const AuthRoute = ({ children }) => {
  const { isLogin } = AuthStore();
  const location = useLocation();
  if (!isLogin) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return children;
  }
};

export default AuthRoute;

import React from 'react'
import styled from "styled-components";


const Tag = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
  background-color: #575DFB;
  color: #fff;
  padding: 0 4px;
  border-radius: 8px;
  word-break: keep-all;
`;

/** 
 * (1)
 * 
 * PROP ATTRIBUTE : color
 * PROP VALUE : primary(default) || secondary || pale
 * 
 * EXAMPLE :
 * <Tags color="primary">답변완료</Tags>
 * 
 */

export default function EducationTag(props) {
  return props.color === "secondary" ? (
    <Tag style={{ backgroundColor: "#9A9EFD" }}>{props.children}</Tag>
  ) : props.color === "pale" ? (
    <Tag style={{ backgroundColor: "#D8D9FF", color: "#9A9EFD" }}>
      {props.children}
    </Tag>
  ) : props.color === "primary" ? (
    <Tag>{props.children}</Tag>
  ) : (
    <Tag>{props.children}</Tag>
  );
}

import create from 'zustand';

const LoginStore = create((set, get) => ({
  username: '',
  password: '',
  isNewToken : false,

  setUserName: (username) => set({username: username}),
  setPassword: (password) => set({password: password}),
  setNewToken: (isNewToken) => set({ isNewToken: isNewToken }),


  getLoginData :()=> {
    return({
      username: get().username,
      password: get().password,
    });
  },

}));

export default LoginStore;
import React, { useState, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LayoutJoin from "../../components/LayoutJoin";
import {Button,TextField,ToggleButtonGroup,ToggleButton,Typography,ListItem,List,ListItemText,ListItemButton} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useDropzone } from "react-dropzone";
import { useDaumPostcodePopup } from "react-daum-postcode";
import ApiService from "../../service/ApiService";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { message } from '../../message/Message';
import ruLocale from 'date-fns/locale/ru';
import koLocale from 'date-fns/locale/ko';

const localeMap = {
  ru: ruLocale,
  ko: koLocale,
};

// 핸드폰 숫자 -> '-' 포맷 추가해주는 함수
function phoneFormatter(num) {
  let formatNum = "";
    if (num.length === 11) {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    } else if (num.length === 8) {
      formatNum = num.replace(/(\d{4})(\d{4})/, "$1-$2");
    } else {
      if (num.indexOf("02") === 0) {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
      } else {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      }
    }

  return formatNum;
}

// 파일 첨부 드래그드롭
function FileDrop({ fileList, setFileList,isValid, setIsValid }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFileList([...fileList, ...acceptedFiles]);
    },
    [fileList]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const removeFile = (file) => () => {
    const newFiles = [...fileList];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFileList(newFiles);
    if (fileList.lentgh === 0) {
      setIsValid(false);
    }
  };

  const drawList = fileList.map((file) => (
    <ListItem key={file.path} disablePadding>
      <ListItemText>{file.path}</ListItemText>
      <ListItemButton
        sx={{ maxWidth: "60px", display: "flex", justifyContent: "center" }}
        onClick={removeFile(file)}
      >
        X
      </ListItemButton>
    </ListItem>
  ));

  return (
    <Box>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps({})} />
        <Box
          sx={{
            border: "1px dashed #999",
            borderRadius: "4px",
            mb: 2,
            p: 2,
            minHeight: "92px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ color: "#999" }}>
            업로드 할 파일을 여기에 끌어다 놓으세요.
          </Typography>
        </Box>
      </div>
      {fileList.length === 0 ? null : (
        <Box sx={{ px: 2, backgroundColor: "#eee", borderRadius: "4px" }}>
          <List>{drawList}</List>
        </Box>
      )}
    </Box>
  );
}

// daum post code 주소찾기
function SearchAddress ({trainerAddress, setTrainerAddress}) {
  const open = useDaumPostcodePopup();

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setTrainerAddress(fullAddress);
  };

  const openSearchAddress = () => {
    open({ onComplete: handleComplete });
  };

  return (
    <TextField
      id="trainerAddress"
      type="search"
      variant="outlined"
      placeholder="클릭 후 주소를 입력해주세요."
      fullWidth
      value={trainerAddress}
      readOnly
      className="input-padding-sm"
      onClick={openSearchAddress}
    />
  );
};

export default function Join() {
  const [trainerName, setTrainerName] = useState(""); // 이름
  const [trainerPhone, setTrainerPhone] = useState(""); // 휴대폰번호
  const [trainerAddress, setTrainerAddress] = useState(""); // 주소
  const [trainerBirth, setTrainerBirth] = useState(null); // 생년월일
  const [trainerGender, setTrainerGender] = useState(""); // 성별
  const [trainerIntroduce, setTrainerIntroduce] = useState(""); // 소개
  const [startMonth, setStartMonth] = useState(null); // 경력시작
  const [endMonth, setEndMonth] = useState(null); // 경력종료
  const [fileList, setFileList] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();

  
  // 폼 전체 입력 확인 -> 활성화
  useEffect(() => {
    if (
      fileList.length !== 0 &&
      trainerName &&
      trainerPhone &&
      trainerAddress &&
      trainerBirth &&
      trainerGender &&
      startMonth &&
      endMonth
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [
    trainerName,
    trainerPhone,
    trainerAddress,
    trainerBirth,
    trainerGender,
    startMonth,
    endMonth,
    fileList
  ]);
   
  // 회원가입 요청 양식 제출
  const handleSubmit = () => {
    ApiService.requestJoin({
      name: trainerName,
      gender: trainerGender,
      birth: trainerBirth,
      phone: trainerPhone,
      address: trainerAddress,
      street: "202호",
      zipcode: "06148",
      description: "설명글 없음.",
      careerStartDate: startMonth,
      careerEndDate: endMonth,
      licensePhotos: fileList,
    })
      .then((response) => {
        console.log(response);
        Swal.fire({
          padding: '12px 16px 20px',
          text: '회원가입 요청이 전송되었습니다. 입력한 연락처로 합격 여부를 알려드립니다.',
          confirmButtonText: '확인',
          confirmButtonColor: '#575DFB'
        });
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          padding: '12px 16px 20px',
          text: message.regist.error.fail,
          confirmButtonText: '확인',
          confirmButtonColor: '#575DFB'
        });
      });
  };

  return (
    <>
      <LayoutJoin title="훈련사 등록 작성하기">
        <h2>1. 기본 정보</h2>
        <Box sx={{ p: 6, mb: 6, backgroundColor: "#fff" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box>
                  <label>
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      성명
                    </Typography>
                  </label>
                  <TextField
                    id="trainerName"
                    type="search"
                    variant="outlined"
                    placeholder="이름"
                    fullWidth
                    className="input-padding-sm"
                    onChange={(event) => {
                      setTrainerName(event.target.value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <label>
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      성별
                    </Typography>
                  </label>
                  <ToggleButtonGroup
                    fullWidth
                    sx={{ height: "54px" }}
                    color="primary"
                    value={trainerGender}
                    exclusive
                    onChange={(event, newData) => {
                      if (newData !== null) {
                        setTrainerGender(newData);
                      }
                    }}
                  >
                    <ToggleButton value="MALE">남자</ToggleButton>
                    <ToggleButton value="FEMALE">여자</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ mb: 2 }}>
                  <label>
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      생년월일
                    </Typography>
                  </label>
                  <LocalizationProvider dateAdapter={ AdapterDateFns } adapterLocale={ localeMap.ko }>
                    <DatePicker
                      openTo="year"
                      views={["year", "month", "day"]}
                      inputFormat={"yyyy-MM-dd"}
                      value={trainerBirth}
                      onChange={(dateValue) => {
                        setTrainerBirth(
                          dateValue.toISOString().substring(0, 10)
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth variant="outlined" readOnly />
                      )}
                      disableFuture={ true }
                      minDate={ new Date("1950-01-01") }
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <label>
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      연락처
                      <span
                        style={{
                          color: "#5F646E",
                          fontSize: 14,
                          fontWeight: "400",
                          marginLeft: 8,
                        }}
                      >
                        입력한 연락처로 합격 여부를 알려드립니다.
                      </span>
                    </Typography>
                  </label>
                  <TextField
                    id="trainerPhone"
                    type="search"
                    variant="outlined"
                    placeholder="휴대폰번호"
                    fullWidth
                    className="input-padding-sm"
                    onChange={(event) => {
                      setTrainerPhone(phoneFormatter(event.target.value));
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <label>
                    <Typography sx={{ mb: 1, fontWeight: 700 }}>
                      거주지 주소
                      <span
                        style={{
                          color: "#5F646E",
                          fontSize: 14,
                          fontWeight: "400",
                          marginLeft: 8,
                        }}
                      >
                        입력한 주소는 반려인들에게 OO동 까지 공개됩니다.
                      </span>
                    </Typography>
                  </label>
                  <SearchAddress
                    trainerAddress={trainerAddress}
                    setTrainerAddress={setTrainerAddress}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <h2>2. 경력 정보</h2>
        <Box sx={{ p: 6, backgroundColor: "#fff", mt: 6 }}>
          <Grid container spacing={2}>
            {/* 소개 */}
            <Grid item xs={12}>
              <Box>
                <label>
                  <Typography sx={{ mb: 1, fontWeight: 700 }}>
                    간략 소개
                    <span
                      style={{
                        color: "#5F646E",
                        fontSize: 14,
                        fontWeight: "400",
                        marginLeft: 8,
                      }}
                    >
                      회사명/근무 기간/하신 업무 (작성해주신 경력에 대해서는
                      추후 사실 여부를 확인할 수 있습니다)
                    </span>
                  </Typography>
                </label>
                <TextField
                  multiline
                  rows={4}
                  id="trainerIntroduce"
                  type="search"
                  variant="outlined"
                  value={trainerIntroduce}
                  placeholder="예)&#13;&#10;1. △△대 반려동물학과 / 2005.03 - 2007.02 / 졸업&#13;&#10;2.□□ 애견 유치원 / 2009.01 - 2011.05 / 고객 응대, 위탁견 관리, 1:1 보호자 교육, 견사 관리"
                  fullWidth
                  className="input-padding-sm"
                  onChange={(event) => {
                    setTrainerIntroduce(event.target.value);
                  }}
                />
              </Box>
            </Grid>
            {/* 경력 라벨 */}
            <Grid item xs={12}>
              <label>
                <Typography sx={{ mb: -1, fontWeight: 700 }}>경력</Typography>
              </label>
            </Grid>
            {/* 경력 시작 */}
            <Grid item xs={4}>
              <Box sx={{ mb: 2 }}>
                <LocalizationProvider dateAdapter={ AdapterDateFns } adapterLocale={ localeMap.ko }>
                  <DatePicker
                    openTo="year"
                    views={["year", "month"]}
                    inputFormat={"yyyy-MM"}
                    mask={"____-__"}
                    value={startMonth}
                    onChange={(dateValue) => {
                      setStartMonth(dateValue.toISOString().substring(0, 10));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={null}
                        fullWidth
                        variant="outlined"
                        readOnly
                      />
                    )}
                    disableFuture={ true }
                    minDate={ new Date("1950-01-01") }
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={{ lineHeight: "56px", textAlign: "center" }}>
                ~
              </Typography>
            </Grid>
            {/* 경력 끝 */}
            <Grid item xs={4}>
              <Box sx={{ mb: 2 }}>
                <LocalizationProvider dateAdapter={ AdapterDateFns } adapterLocale={ localeMap.ko }>
                  <DatePicker
                    openTo="year"
                    views={["year", "month"]}
                    inputFormat={"yyyy-MM"}
                    mask={"____-__"}
                    value={endMonth}
                    onChange={(dateValue) => {
                      setEndMonth(dateValue.toISOString().substring(0, 10));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={null}
                        fullWidth
                        variant="outlined"
                        readOnly
                      />
                    )}
                    disableFuture={ true }
                    minDate={ new Date("1980-01-01") }
                  />
                </LocalizationProvider>
              </Box>
            </Grid>

            {/* 자격증 라벨 */}
            <Grid item xs={12}>
              <label>
                <Typography sx={{ mb: -1, fontWeight: 700 }}>
                  훈련 자격증 *
                  <span
                    style={{
                      color: "#5F646E",
                      fontSize: 14,
                      fontWeight: "400",
                      marginLeft: 8,
                    }}
                  >
                    훈련 자격증 이미지 파일을 첨부해 주세요.
                  </span>
                </Typography>
              </label>
            </Grid>
            {/* 자격증 파일첨부 */}
            <Grid item xs={12}>
              <FileDrop
                fileList={fileList}
                setFileList={setFileList}
                isValid={isValid}
                setIsValid={setIsValid}
              />
            </Grid>
          </Grid>
        </Box>
      </LayoutJoin>
      <Button
        variant="contained"
        size="large"
        sx={{ display: "block", width: 460, margin: "80px auto" }}
        disabled={!isValid}
        onClick={handleSubmit}
      >
        제출하기
      </Button>
    </>
  );
}

import axios from "axios";
import { USER_API_URI } from "./Host";

const client = axios.create({
  baseURL: USER_API_URI,
});

// API 요청 URI, 기본 HEADER만 담고 있음
class ApiService {
  //로그인 요청
  requestLogin = async ({ username, password }) => {
    return await axios.post(
      USER_API_URI + "/signup",
      { username, password },
      { headers: { "Content-Type": "application/json" } }
    );
  };

  //회원가입 요청
  requestJoin = async ({
    name,
    gender,
    birth,
    phone,
    address,
    street,
    zipcode,
    description,
    careerStartDate,
    careerEndDate,
    licensePhotos,
  }) => {
    return await client.post(
      USER_API_URI + "/signup/info",
      {
        name,
        gender,
        birth,
        phone,
        address,
        street,
        zipcode,
        description,
        careerStartDate,
        careerEndDate,
        licensePhotos,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  //계정등록 요청
  requestRegistAccount = async ({ username, password, uuid }) => {
    return await client.post(
      USER_API_URI + `/signup/${uuid}`,
      { username, password },
      { headers: { "Content-Type": "application/json" } }
    );
  };

  //아이디찾기 요청
  requestFindAccount = async ({ username, gender, birth }) => {
    return await client.post(
      USER_API_URI + `/find`,
      { name: username, gender, birth },
      { headers: { "Content-Type": "application/json" } }
    );
  };

  //비밀번호 변경 요청
  registNewPassword = async ({ userId, username, gender, birth, uuid }) => {
    return await client.post(
      USER_API_URI + `/find/update/password`,
      { userId, name: username, gender, birth, uuid },
      { headers: { "Content-Type": "application/json" } }
    );
  };
}

export default new ApiService();
